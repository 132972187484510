@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.question-presenter {
    padding-left: 20px;
    padding-right: 20px;

    .question-text {
        font-size: 1.3rem;
        font-weight: 600;
        margin-bottom: 5px;
        color: $black;
        padding-left: 10px;
        padding-right: 10px;

        .question-sub-text {
            font-size: 0.9rem;
            font-weight: normal;
            //padding-left: 30px;
        }
    }

    .reinforcement-text {
        margin-top: 5px;
        margin-bottom: 5px;
        min-height: 21px;
    }

    .form-control:disabled, .form-control[readonly] {
        background-color: $white !important;
    }

    @include media-breakpoint-down(sm) {
        padding: 10px;
    }
}
