@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.questionnaire-navigation-container {
    label {
        font-weight: 600;
    }

    .nav-tiles {
        justify-content: center;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .nav-item {
            width: 20%;

            .nav-link {
                position: relative;
                cursor: pointer;
                border-radius: 5px;
                color: $white;
                background-color: $skillshub-blue;
                border-color: $skillshub-blue;
                background-size: 40%;
                background-position-x: center;
                background-position-y: 1rem;
                background-repeat: no-repeat;
                padding-top: 6rem;
                font-size: 1.4rem;

                .completed-icon {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    font-size: 2rem;
                    border: 1px solid $skillshub-grey;
                    border-radius: 50%;
                    background: $skillshub-grey;
                    color: $white;
                }
                /* this is to make thesae navlinks behave completely differently from normal ones to match client's design */
                &.nav-link-notactive {
                    background-color: $white !important;
                    border-color: $skillshub-grey !important;
                    color: $skillshub-grey !important;

                    &:hover {
                        color: $white !important;
                    }
                }

                &:hover {
                    background-color: $skillshub-blue !important;

                    .completed-icon {
                        border: none;
                    }
                }

                &.active {
                    background-color: $skillshub-blue;
                    border-color: $skillshub-blue;

                    .completed-icon {
                        border: none;
                    }

                    &:hover {
                        background-color: darken($skillshub-blue,10) !important;
                    }
                }


                &.campaign-navlink {
                    background-image: url('../../layout/icons/megaphone_grey.png');

                    &.active, &.nav-link-notactive:hover {
                        background-image: url('../../layout/icons/megaphone_white.png');
                    }
                }

                &.questionnaire-navlink {
                    background-image: url('../../layout/icons/question_grey.png');

                    &.active, &.nav-link-notactive:hover {
                        background-image: url('../../layout/icons/question_white.png');
                    }
                }

                &.people-navlink {
                    background-image: url('../../layout/icons/employees_grey.png');

                    &.active, &.nav-link-notactive:hover {
                        background-image: url('../../layout/icons/employees_white.png');
                    }
                }

                &.schedule-navlink {
                    background-image: url('../../layout/icons/event_grey.png');

                    &.active, &.nav-link-notactive:hover {
                        background-image: url('../../layout/icons/event_white.png');
                    }
                }

                &.review-navlink {
                    background-image: url('../../layout/icons/paper-plane_grey.png');

                    &.active, &.nav-link-notactive:hover {
                        background-image: url('../../layout/icons/paper-plane_white.png');
                    }
                }

                .navitem-text {
                    height: 4rem;
                    font-size: 1.2rem;
                }
                /*
                &.completed-link {
                    box-shadow: 5px 5px 10px lighten($skillshub-blue,20) inset, -5px -5px 10px lighten($skillshub-blue,20) inset, 5px 5px 10px lighten($skillshub-blue,20), -5px -5px 10px lighten($skillshub-blue,20);
                    border-color: $skillshub-blue;
                    background-color: lighten($skillshub-blue,30);
                }
*/
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .nav-tiles {
            .nav-item {

                .nav-link {
                    height: 9.5rem;

                    &:hover {
                    }

                    .navitem-text {
                        font-size: 1rem;
                        height: 4.5rem;
                        line-height: 20px;
                    }
                }

                .nav-icon {
                    font-size: 55px;
                }
            }
        }
    }
}
