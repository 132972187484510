@import "../../styles/_colors.scss";

.nextback-buttons {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-top: 4px;
    margin-right: 0px;
    margin-left: 0px;

    .hidden{
        display: none;
    }

    .back-button-container {
        text-align: left;
        padding-left: 0px;
    }

    .next-button-container {
        text-align: right;
        padding-right: 0px;
    }
}
