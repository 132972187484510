﻿// Global styling, not specific to any component.
//
// NOTE this file should be very small.  Do not add anything here without a second opinion!
//

@import "_colors.scss";
@import "_breakpoints.scss";

// Use the body colours.
body {
    background-color: $body-bg;
    color: $body-color;
}

// Dropdown toggles should not be rounded on the left in btn-groups.
.btn-group {
    > .dropdown {
        > .dropdown-toggle {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left-style: none;
        }
    }
}

// formatting for radio buttons
.radio {

    input {
        margin-right: 1rem;
        accent-color: $skillshub-blue;
    }

    label {
        margin-right: 2rem;
        font-weight: normal !important;
    }
}

// format for tool tips
[data-toggle="tooltip"] {
    color: $primary;
    font-size: 16px;
    margin-left: 2px;
}

@include media-breakpoint-down (sm) {
    a.btn {
        padding: 0.4rem 0.5rem;
        font-size: 12px;
    }
}