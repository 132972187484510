@import "../../styles/_colors.scss";

.table-row-buttons {
    width: 100%;
    text-align: right;
    // Give some space between buttons.
    .btn {
        margin-right: 2px;
    }

    .btn-group {
        margin-right: 2px;

        .btn {
            margin-right: 0px;
        }

        .btn-outline-primary {
            background: $white;

            &:hover {
                background-color: $primary;
                color: $white;
                border-color: $primary;
            }
        }
    }
}
