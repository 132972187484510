@import "../../../styles/_colors.scss";

.question-textbox {

    .text-box-number {
        position: absolute;
        top: 5px;
        left: 30px;
        font-size: 1.2rem;
        color: $black;
    }

    textarea {
        margin-bottom: 5px;
        padding: 9px 36px;
    }
}
