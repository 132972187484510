@import "../../styles/_colors.scss";

.report-name-modal {

    .modal-header {
        background-color: $skillshub-blue;
        color: $dark-color;
    }

    .input-group {
        margin-top: 10px;
    }

    .form-buttons {
        label {
            color: $skillshub-blue;
        }

        .analyser-image-button {
            height: 80px;
            width: auto;
            border-color: $white !important;
            background-color: transparent !important;
            padding: 8px;
            color: $skillshub-blue;

            &.btn-secondary:focus, &.btn-secondary.focus {
                border-color: $white !important;
                background-color: transparent !important;
                box-shadow: none !important;
            }

            .download-icon {
                font-size: 63px;
                padding: 5px;
            }

            img {
                height: 100%;
                width: auto;
            }

            &:hover {
                padding: 5px;

                .download-icon {
                    padding: 0px;
                }
            }
        }

        .help-text {
            color: $skillshub-blue;
            font-style: italic;
            font-size: 12px;
        }
    }
}