@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

/* gradient - slightly darkened for header text to show to light - left to right */
$impacts-background-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url(./background/header5_adjusted.jpg);
/* standard gradient - very dark top to bottom */
/*$impacts-background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url(./background/header5_adjusted.jpg);*/
/* no gradient */
/*$impacts-background-image: url(./background/header2_adjusted.jpg);*/

.banner {
    //background-image: $impacts-background-image;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
    background-color: #f2f2f2;
    /* added to hide the gap between the banner and the rounded sidebar menu */
    width: 103%;
    color: $gray-800;
    text-align: center;

    .bannerWithButtons {
        margin-top: 60px;
        margin-left: 25%;
    }

    .bannerWithPills {
        height: 120px;
        padding-top: 20px;

        &.with-sub-title {
            margin-top: 40px;
        }
    }

    .bannerNoPills {
        // no pills
        height: 160px;
        padding-top: 65px;
    }

    .searchBar {
        margin-top: 55px;

        &.with-sub-title {
            margin-top: 32px;
        }
    }

    .report-builder-button {
        margin-top: 30px;
    }

    .btn {
        &.btn-outline-secondary {
            background: $white;
        }

        &.btn-outline-primary {
            background: $white;

            &:hover {
                background-color: darken($primary, 10);
                color: $white;
                border-color: darken($primary, 10);
            }
        }
    }

    h1{
        font-size: 2rem;
    }

    h2{
        font-size: 1.5rem;
    }

    .sub-title {
        // move closer to the banner title
        margin-top: -10px;
    }

}

@include media-breakpoint-up(lg) {
    .banner {
        margin-left: -50px;
        padding-left: 50px;

    }
}

@include media-breakpoint-down(md) {
    .banner {
        height: 240px;
        margin-left: -40px;
        padding-left: 40px;
        padding-top: 15px;
        width: 107%;

        .bannerNoPills {
            height: 117px;
            padding-top: 45px;
        }
        .bannerWithPills {
            height: 70px;

        }
    }
}

@include media-breakpoint-down(sm) {
    .banner {
        height: auto;
        padding-right: 0px;
        padding-left: 25px;
        margin-left: -25px;
        padding-top: 10px;

        .container-fluid {
            padding: 0px 10px !important;
        }

        .bannerWithPills {
            height: unset;
            margin-top: 10px;

            &.with-sub-title {
                margin-top: 10px;
            }

            .sub-title {
                margin-top: 0px;
            }
        }

        .bannerNoPills {
            height: unset;
            padding-top: 30px;
        }

        .searchBar {
            margin-top: 10px;

            &.with-sub-title {
                margin-top: 10px;
            }
        }


        h1 {
            font-size: 17px;
            margin-bottom: 5px;
        }

        h2 {
            font-size: 12px;
        }

        a.btn {
            padding: 0.4rem 0.5rem;
            font-size: 12px;
        }

        .report-builder-button {
            margin-top: 0px;
        }
    }
}
