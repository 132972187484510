@import "../../../styles/_colors.scss";

.uploaded-image-preview {
    margin-bottom: 0.2rem;
    border-color: $gray-300;
    border-style: solid;
    border-width: 1px;
    padding: 4px 4px 4px 4px;
    text-align: center;

    .uploaded-image-preview-no-image {
        color: $gray-700;
    }
    // Large
    &.uploaded-image-preview-lg {
        .uploaded-image-preview-img {
            max-height: 300px;
        }

        .uploaded-image-preview-no-image {
            color: $gray-700;

            .uploaded-image-preview-no-image-icon {
                font-size: 4rem;
            }
        }
    }
    // Small (e.g. as a thumbnail).
    &.uploaded-image-preview-sm {
        .uploaded-image-preview-img {
            max-height: 80px;
        }

        .uploaded-image-preview-no-image {
            font-size: 0.6rem;

            .uploaded-image-preview-no-image-icon {
                font-size: 1.5rem; 
            }
        }
    }
}
