@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

$background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(./traffic-332857_1920.jpg);
//$background-image: url(./background.jpg);

body[data-background="background"] {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: none;
    //color: $dark-color;
    min-height: 100vh;
    background-attachment: fixed;
    //background-color: $gray-100;
}

.apexcharts-tooltip {
    color: $gray-800;
}

.background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    /*    background-color: $dark-bg;
    color: $dark-color;*/
    //background-image: $background-image;

    height: auto;
    min-height: 100%;
    /*    // If we are showing a Banner within a background, turn off the banner's background image.
    // If we want the banner background image to be different to the Background image we may want to turn this off.
    .banner {
        background-image: none;
        background-color: transparent;

        // We also want to get rid of excess height and padding at the bottom.
        height: auto;
        padding-bottom: 6px;
    }*/

    &.background-center-children-none {
        /* Do nothing */
    }

    &.background-center-children-vertically {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &.background-center-children-horizontally {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}