.form-buttons {
    text-align: right;
    // Give some space between buttons.
    .btn {
        margin-right: 2px;
    }

    .btn-group {
        margin-right: 2px;

        .btn {
            margin-right: 0px;
        }
    }
    /*border-top: 1px solid lightgray;*/
    &:not(.form-buttons-no-padding) {
        margin-top: 2em;
        margin-bottom: 2em;
        padding-top: 4px;
    }
}
