@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.subscription-template-list-main-container {

    .highlighted-name {
        color: $skillshub-blue;
        font-weight: 600;
    }

    .form-check-input{
        margin-left: 10px;
    }
}
