@import "../../styles/_colors.scss";

.external-login-button {
    color: $white;

    &:hover {
        color: $gray-100;
    }

    &.microsoft {
        background-color: #20a8d8;
    }

    &.google {
        background-color: #d34836;
    }

    &.facebook {
        background-color: #3b5998;
    }

    &.twitter {
        background-color: #00aced;
    }
}
