@import "../../styles/_colors.scss";

.dashboard-questionnaire-card {
    .card-body {
        color: $gray-600;

        .dashboard-questionnaire-card-actions {
            margin-top: 4px;
            //border-top: 1px solid $gray-300;
            padding-top: 8px;
        }

        .progress {
            height: 1.5rem;
        }

        .card-questionnaire-description {
            height: 5rem;
        }

        .not-close-to-end-date {
            color: $gray-500;
        }

        .overdue {
            color: $red;
        }

        .close-to-end-date {
            color: $skillshub-orange;
        }

        .completed {
            color: $green;
        }
    }

    .card-footer {
        .btn-group {
            margin-right: 10px;
        }
    }

    &.type-Campaign {
        .card-body {
            .dashboard-questionnaire-card-title {
                color: $campaign-color;
                height: 2.5rem;
            }

            .progress {
                border: 1px solid $campaign-color;
                background-color: lighten($campaign-color,40);

                .progress-bar {
                    background-color: lighten($campaign-color,15%);

                    span {
                        font-weight: bold;
                        color: darken($campaign-color,10%);
                    }
                }
            }
        }

        .card-footer {
            .btn-questionnaire {
                background-color: $campaign-color !important;
                border-color: $campaign-color !important;

                &:hover {
                    background-color: darken($campaign-color,5%);
                    box-shadow: 0 0 5px 0.1rem $campaign-color !important;
                }

                &:focus {
                    background-color: darken($campaign-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $campaign-color !important;
                    border-color: $campaign-color !important;
                }
            }

            .btn-outline-questionnaire {
                border-color: $campaign-color !important;
                color: $campaign-color !important;

                &:hover {
                    box-shadow: 0 0 5px 0.1rem $campaign-color !important;
                    background-color: $campaign-color !important;
                    border-color: $campaign-color !important;
                    color: $white !important;
                }

                &:focus {
                    background-color: darken($campaign-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $campaign-color !important;
                    border-color: $campaign-color !important;
                    color: $white !important;
                }

                &:active {
                    background-color: darken($campaign-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $campaign-color !important;
                    border-color: $campaign-color !important;
                    color: $white !important;
                }
            }
        }
    }

    &.type-SelfService {
        .card-body {
            .dashboard-questionnaire-card-title {
                color: $selfservice-color;
                height: 2.5rem;
            }

            .progress {
                border: 1px solid $selfservice-color;
                background-color: lighten($selfservice-color,35%);

                .progress-bar {
                    background-color: lighten($selfservice-color,15%);

                    span {
                        font-weight: bold;
                        color: darken($selfservice-color,10%);
                    }
                }
            }
        }

        .card-footer {
            .btn-questionnaire {
                background-color: $selfservice-color !important;
                border-color: $selfservice-color !important;

                &:hover {
                    background-color: darken($selfservice-color,5%);
                    box-shadow: 0 0 5px 0.1rem $selfservice-color !important;
                }

                &:focus {
                    background-color: darken($selfservice-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $selfservice-color !important;
                    border-color: $selfservice-color !important;
                }
            }

            .btn-outline-questionnaire {
                border-color: $selfservice-color !important;
                color: $selfservice-color !important;

                &:hover {
                    box-shadow: 0 0 5px 0.1rem $selfservice-color !important;
                    background-color: $selfservice-color !important;
                    border-color: $selfservice-color !important;
                    color: $white !important;
                }

                &:focus {
                    background-color: darken($selfservice-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $selfservice-color !important;
                    border-color: $selfservice-color !important;
                    color: $white !important;
                }

                &:active {
                    background-color: darken($selfservice-color,10%) !important;
                    box-shadow: 0 0 5px 0.1rem $selfservice-color !important;
                    border-color: $selfservice-color !important;
                    color: $white !important;
                }
            }
        }
    }
}