@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

$impacts-questionnaire-background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url(../../shared/background/teacher-3765909_1920Narrow.jpg);

.complete-questionnaire {
    border-radius: 0;
    padding-top: 80px !important;
    // border: 1px solid lighten($skillshub-blue,20);
    // box-shadow: 5px 10px 20px 5px $card-color;
    // max-width: 98%;

    .banner {
        background-image: $impacts-questionnaire-background-image;
        margin-left: 0px;
        padding-left: unset;
        text-align: center;
        width: 100%;
        background-position: center;
        background-size: cover;
    }

    .header-info-container {
        border: 3px solid $gray-200;
        border-radius: 20px;
        padding: 20px;
        margin-bottom: 10px;

        .personal-info-container, .learningunit-info-container, .questionnaire-description {
            padding-top: 10px;

            label {
                font-weight: 600;
            }

            input {
                border-radius: 0;

                &:disabled {
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        .personal-info-container, .learningunit-info-container {
            border-top: 1px solid lighten($primary,40);

            label {
                display: block;
            }
        }

        .personal-info-container {
            [name="lineManagerName"], [name="lineManagerEmail"] {
                &:disabled {
                    padding-left: 0px;
                }
            }
        }

        .questionnaire-description {
            padding-bottom: 20px;

            span {
                white-space: pre-wrap;
            }
        }
    }

    .sub-header {
        padding-bottom: 10px;
        color: $primary;
    }

    .questionnaire-container {
        //border-radius: 2px;
        //border: 1px solid $gray-200;
        //padding: 10px 20px;

        .section-header-initial {
            font-size: 3rem;
            color: $skillshub-blue;
            display: inline-block;
            margin-right: 30px;
            width: 1.9rem;
        }

        .section-header {
            display: inline-block;
            font-size: 1.4rem;
            color: $skillshub-blue;
            font-weight: 600;
            //margin-top: 1rem;
            //margin-bottom: 1rem;
        }

        .section-description {
            font-size: 1.1rem;
            font-weight: normal;
            //margin-left: 60px;
            margin-bottom: 10px;
        }

        .question-container {
            border-radius: 20px;
            border: 3px solid $gray-200;
            margin-bottom: 10px;
            padding: 10px;

            .question-number {
                margin-left: 30px;
                font-size: 1.2rem;
                font-weight: 600;
                color: $gray-500;
                margin-bottom: 10px;

                .strong {
                    color: $black;
                    text-decoration: underline;
                    text-decoration-color: $skillshub-blue;
                    text-decoration-thickness: 3px;
                }
            }

            .question-presenter {
                border-radius: 0.5rem;
            }
        }
    }

    .common-header {
        margin-bottom: 10px;
    }

    .header-info-container {
        font-size: 1rem;

        h2 {
            color: $skillshub-blue;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        label {
            font-weight: 600;
            margin-right: 10px;
        }
    }


    @include media-breakpoint-up(sm) {
        .banner {
            height: 160px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 5px !important;

        .questionnaire-container {
            .section-description {
                font-size: .9rem;
                margin-left: unset;
            }
        }

        .banner {
            height: 58px;
        }
    }
}



