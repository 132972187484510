@import "../../../../styles/_colors.scss";
@import "../../../../styles/_breakpoints.scss";

.people-tab-top {
    .col-inline-heading-button {
        padding-left: 0px;
    }
}

.people-tab-main-container {
    .add-user-details-container {
        overflow-y: scroll;
        height: 25rem;

        .add-user-details {
            padding-bottom: 0.5rem;
            border-bottom: 3px solid lighten($skillshub-blue, 25%);

            .detail-label {
                font-weight: 400;
                font-size: 0.8rem;
            }

            .detail-help {
                font-weight: 400;
                font-size: 0.6rem;
                padding-left: 15%;
            }

            .detail-input-box {
                height: 2rem !important;
                font-size: 0.7rem !important;
            }
        }
    }

    .nav-tiles {
        justify-content: center;

        .nav-item {
            width: 12rem;
            padding: 0px;
            margin-right: 3px;
            margin-left: 3px;

            .nav-link {
                height: 12rem;
                border-radius: 5px;
                color: $skillshub-grey;
                background-color: $white;
                border-color: $skillshub-grey;

                &:hover {
                    background-color: darken($skillshub-blue,10);
                    color: $white;
                }

                &.active {
                    background-color: $skillshub-blue;
                    border-color: $skillshub-blue;
                    color: $white;

                    &:hover {
                        background-color: darken($skillshub-blue,10);
                    }
                }

                .navitem-text {
                    height: 4rem;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .current-user-list {
        margin-bottom: 1rem;
        border: 1px solid lighten($skillshub-blue, 40);
        padding: 20px;
        border-radius: 0.2rem;
        box-shadow: 5px 10px 20px 5px $card-color;

        .list-group-item {
            border: none;
            color: $success;
            font-weight: 600;
            padding: 0.25rem 1.25rem;

            &.rejected {
                color: $danger;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .nav-tiles {

            .nav-item {
                width: 7rem;

                .nav-icon {
                    font-size: 45px;
                }

                .nav-link {
                    height: 7rem;


                    .navitem-text {
                        height: 3rem;
                        font-size: 1rem;
                        line-height: 1.1;
                    }
                }
            }
        }
    }
}

