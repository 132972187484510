@import "../../../styles/_colors.scss";

.informative-list-modal {

    .analyserchecklist-container {
        input[type=checkbox] {
            transform: scale(1.6);
            margin-left: 8px;
        }
    }

    .arrow-image {
        content: url('../../layout/icons/Arrow (blue).png');
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-right: 5px;
    }

    .selections-label {
        color: $skillshub-blue;
        display: inline-block;
        font-size: 13px;
    }

    h6 {
        padding-top: 4px;
    }
}