@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.report-builder {
    .report-selector-list {
        margin-bottom: 10px;
        margin-top: 5%;
    }

    .select-company-report {
        margin-top: 12px;
        margin-left: 7%;
    }

    .company-report-select-label {
        margin-top: 10px;
        margin-left: 10%;
    }

    .add-everything-label {
        margin-top: 25px;
    }

    .add-everything-to-package {
        margin-top: 27px;
    }

    .select-company-report-row {
        margin-top: 5%;
    }

    .company-label {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .section-selector-label {
        margin-left: 0.5%;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .department-selector-label {
        margin-left: 0.5%;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .participant-selector-label {
        margin-left: 0.5%;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .select-all-check {
        margin-left: 45%;
    }

    .select-all-label {
        margin-left: 50%;
    }

    .single-select {
        margin-left: 2%;
    }

    h3 {
        color: $primary;
        margin-top: 20px;
    }

    @include media-breakpoint-down(sm) {

        .add-everything-label {
            margin-top: 0px;
            margin-left: 45px;
        }

        .add-everything-to-package {
            margin-top: 2px;
            margin-left: 25px;
        }

        .select-company-report {
            margin-top: 8px;
            margin-left: 25px;
        }

        .company-report-select-label {
            margin-top: 6px;
            margin-left: 45px;
        }

        .select-all-check {
            margin-top: 12px;
            margin-left: 25px;
        }

        .select-all-label {
            margin-top: 10px;
            margin-left: 45px;
        }
    }
}