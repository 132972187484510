@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.questionnaire-main-container {

    .questionnaire-name {
        color: $skillshub-blue;
        line-height: 1em;
        min-height: 1em;
    }

    label {
        font-weight: 600;
    }

    .other-input {
        height: calc(1em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #5c6873;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid $gray-400;
        border-radius: 1rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus-visible {
            outline: none;
        }

        .saved-text {
            color: $gray-100;
        }
    }

    .custom-switch {
        text-align: center;
    }

    .form-buttons {

        .btn-group {
            margin-right: 10px;
        }
    }

    @include media-breakpoint-down(md) {
        [data-toggle="tooltip"] {
            display: none;
        }

        .btn-group.quickdate-button-group {
            margin-top: 3px;

            .btn {
                padding: 5px;
                font-size: 11px;
            }
        }
    }
}


.modal-input-col {
    margin-bottom: 10px;
    // max-height: 500px;

    &.modal-input-col-left {
        //overflow-y: scroll;
    }

    &.modal-input-col-right {
        border-left: 1px solid $card-color;
    }
}
