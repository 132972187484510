@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.user-progress-dashboard-tab {
    .user-progress-dashboard-tab-sub-heading {
        color: $primary;
    }

    .user-progress-dashboard-tab-item {
        .user-progress-dashboard-tab-item-right-side {
            display: flex;
            flex-direction: column;
            min-height: 100%;

            .user-progress-dashboard-tab-item-right-side-score {
                flex-grow: 1;
            }

            .user-progress-dashboard-tab-item-right-side-date {
                text-align: center;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .list-group-item-heading{
            text-align: center;
            font-size: 1rem;
        }


    }
}


