@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

$text-color: $skillshub-green;

.app-header {
    background-color: $white;
    color: $text-color;
    border-bottom-style: none;
    //box-shadow: rgba($gray-300, 10) 0px 10px;
    // Need a little extra space on xs and sm displays as we show the search bar under the rest of the items.
    // commented out for IMPACTS as we don't need the searchbar
    /*    @include media-breakpoint-down(sm) {
        height: 100px;
    }
*/
    .nav-item {
        a.nav-link {
            color: $text-color;
            min-height: 2rem;
            display: flex;
            align-items: center;
            margin-right: 2px;

            &.active {
                font-weight: 600;
                background-color: $skillshub-green;
                border-radius: 0.5rem;
                color: white;

                &:hover {
                    border-bottom-style: none;
                    background-color: darken($skillshub-green, 10%);
                }
            }

            &.signin-nav {
                &:hover {
                    border-bottom-color: white !important;
                }
            }

            &:hover {
                border-bottom-color: $text-color;
                border-bottom-width: 2px;
                border-bottom-style: solid;
            }
        }
    }

    .sb-avatar__text {
        background: $skillshub-blue !important;
    }

    .menu-nav {
        display: none;

        .nav-icon {
            display: none;
        }
    }

    @include media-breakpoint-up(sm) {
        .menu-nav {
            display: flex;
        }
    }
    /*// Undo silly logic by coreui that mispositions badges for nav-item links.
    .nav-item {
        .nav-link {
            .badge {
                position: relative;
                top: unset;
                left: unset;
                margin-top: unset;
                margin-left: 4px;
            }
        }
    }*/
    @include media-breakpoint-up(md) {
        .menu-nav {
            display: flex;
        }
    }
    // Restore the normal padding on links for lg and above.
    @include media-breakpoint-up(lg) {
        .nav-item {
            .nav-link {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }
}



