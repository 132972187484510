@import "../../../styles/_colors.scss";

$default-card-color: $skillshub-purple;

.cards-or-table {

    .table-user-checkbox {
        margin-left: 50px;
    }

    &.cards-or-table-cards {

        .cards-or-table-card {

            .card-footer {
                background-color: $gray-100 !important;
                border-radius: 0px;

                .btn-primary {
                    background-color: $default-card-color;
                    border-color: $default-card-color
                }

                .btn-outline-primary {
                    background-color: $white;
                    color: $default-card-color;
                    border-color: $default-card-color;

                    &:focus {
                        box-shadow: 0 0 0 0.2rem $default-card-color;
                    }

                    &:hover {
                        background-color: $primary;
                        color: $white;
                        border-color: $primary;
                    }
                }

                .card-user-checkbox {
                    margin-top: 12px;
                    margin-left: 47%;
                }
            }

            &.card {
                border-radius: 0.2rem;
                box-shadow: 5px 10px 20px 5px $card-color;
                border-color: $card-color;
                margin-left: 5px;

                .card-body {
                    color: $gray-600;

                    .card-title {
                        margin-top: -24px;
                        margin-left: -24px;
                        margin-right: -24px;
                        padding: 24px;
                        color: $default-card-color;
                        overflow: hidden;
                        height: 5rem;
                    }
                }

                &.card-template {

                    .card-body {
                        .card-title {
                            color: $template-color;
                        }
                    }

                    .btn-primary {
                        &.btn-questionnaire {
                            background-color: $template-color !important;
                            border-color: $template-color !important;
                        }
                    }

                    .btn-outline-primary {
                        &.btn-outline-questionnaire {
                            color: $template-color !important;
                            border-color: $template-color !important;

                            &:hover {
                                background-color: $template-color !important;
                                color: $white !important;
                            }

                            &:focus {
                                box-shadow: 0 0 0 0.2rem $template-color !important;
                                background-color: $template-color !important;
                                color: $white !important;
                            }
                        }
                    }

                    .dropdown-item:focus {
                        background-color: $template-color;
                        color: $white;
                    }
                }

                &.card-selfservice {

                    .card-body {
                        .card-title {
                            color: $selfservice-color;
                        }
                    }

                    .btn-primary {
                        &.btn-questionnaire {
                            background-color: $selfservice-color !important;
                            border-color: $selfservice-color !important;
                        }
                    }

                    .btn-outline-primary {
                        &.btn-outline-questionnaire {
                            color: $selfservice-color !important;
                            border-color: $selfservice-color !important;

                            &:hover {
                                background-color: $selfservice-color !important;
                                color: $white !important;
                            }

                            &:focus {
                                box-shadow: 0 0 0 0.2rem $selfservice-color !important;
                                background-color: $selfservice-color !important;
                                color: $white !important;
                            }
                        }
                    }

                    .dropdown-item:focus {
                        background-color: $selfservice-color;
                        color: $white;
                    }
                }

                &.card-campaign {

                    .card-body {
                        .card-title {
                            color: $campaign-color;
                        }
                    }

                    .btn-primary {
                        &.btn-questionnaire {
                            background-color: $campaign-color !important;
                            border-color: $campaign-color !important;
                        }
                    }

                    .btn-outline-primary {
                        &.btn-outline-questionnaire {
                            color: $campaign-color !important;
                            border-color: $campaign-color !important;

                            &:hover {
                                background-color: $campaign-color !important;
                                color: $white !important;
                            }

                            &:focus {
                                box-shadow: 0 0 0 0.2rem $campaign-color !important;
                                background-color: $campaign-color !important;
                                color: $white !important;
                            }
                        }
                    }

                    .dropdown-item:focus {
                        background-color: $campaign-color;
                        color: $white;
                    }
                }
            }
        }
    }
    // text colours for buttons on different card
    .text-campaign {
        color: $campaign-color;
    }

    .text-selfservice {
        color: $selfservice-color;
    }

    .text-template {
        color: $template-color;
    }

    .particpant-progress-bar {
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: 0.65625rem;
        background-color: #f3f4f5;
        border-radius: 0.25rem;
        color: $white;
    }

    .particpant-progress-bar-background {
        display: flex;
        height: 1rem;
        overflow: hidden;
        font-size: 0.65625rem;
        background-color: $skillshub-blue;
        border-radius: 0.25rem;
    }
}
