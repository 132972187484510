@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

$background-image: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.5)), url(./background/impacts.jpg);
$toolbar-stuck-image: linear-gradient(rgba(56 56 56 / 80%), rgba(0, 0, 0, 0.1));

.banner {

    width: 100%;
    //background-color: $dark-bg;
    color: $dark-color;
    //background-image: $background-image;

    .sticky-toolbar.sticky-toolbar-stuck {
        background-image: $toolbar-stuck-image;
        /*background-color: $gray-300;*/
        /*color: $dark-color;*/
    }
    // If we are using our full-height variant, then make sure we fill the available screen area.
    &.banner-full-height {
        height: auto;
        min-height: 100%;
    }

    h1 {
        //font-size: 20px;
    }
}

// Padding and sizes on different screen sizes.
.banner {
    height: 160px;
/*    padding-left: 10px;
    padding-right: 10px;*/
    padding-top: 10px;
    padding-bottom: 15px;
}

@include media-breakpoint-up(sm) {
    .banner {
        height: 300px;
/*        padding-left: 20px;
        padding-right: 20px;*/
        padding-top: 20px;
        padding-bottom: 20px;

        h1 {
            //font-size: 25px;
        }
    }
}

@include media-breakpoint-up(md) {
    .banner {
        //height: 300px;
/*        padding-left: 20px;
        padding-right: 20px;*/
        padding-top: 30px;
        padding-bottom: 20px;
    }
}
