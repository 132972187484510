@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

.main-questionnaire-overview-container {
    input {
        margin-bottom: 5px;
    }

    .resultsContainer {
        padding: 10px 25px;
    }



    .question-area {
        border-color: $black;
        border-width: 1px;
        border-style: solid;
        background-color: $white;
    }

    .border-grey {
        border-color: grey;
        border-width: 1px;
        border-style: solid;
        background-color: white !important;
    }

    .header {
        height: 60px;
        padding-top: 10px;
    }

    .segment {
        border: solid 2px;
        border-color: $gray-200;
        margin-top: 1rem;
        margin-left: 2px;
        margin-right: 2px;
    }

    .subSegment {
        padding-top: 0.5rem;
        border-bottom: solid 2px;
        border-color: $gray-200;
        padding-left: 1rem;
        padding-right: 1rem;
        max-height: 30vw;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;

        &.graph {
            max-height: 40vw;
        }
    }

    .title {
        color: $secondary;
        margin-bottom: 10px;
    }

    .sub-title {
        color: $secondary;
        margin-top: 5px;
    }

    .center-divide {
        width: 5px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .imageButton {
        background-color: transparent !important;
        color: none;
        width: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        fill: none;
        transition: none;

        &.btn-secondary {
            background-color: transparent !important;
            border-color: transparent !important;
        }

        &:focus {
            fill: none;
            box-shadow: none !important;
            background-color: transparent;
            border: none;
        }

        &:active {
            fill: none;
            box-shadow: none;
            background-color: transparent;
            border-color: transparent !important;
            border: none;
            outline: none;
            box-shadow: none;
        }

        &:hover {
            fill: none;
            box-shadow: none;
            background-color: transparent;
        }
    }

    .hamburger {
        content: url('../../layout/icons/Move (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    .arrowImage {
        content: url('../../layout/icons/Arrow (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
        position: relative;
    }

    .dragComponent {
        background-color: $gray-100;
        color: black;
        margin: 1rem;
        margin-left: -1rem;
        border-radius: 2rem;
        max-width: 96%;
        padding-top: 7px;

        .fa-minus {
            color: $skillshub-blue;
            width: 17px !important;
            margin-left: 5px;
            margin-right: 5px;
        }

        h6 {
            color: $skillshub-blue;
            display: inline;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .questionResults {
    }

    .pdfImage {
        content: url('../../layout/icons/PDF (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    .printImage {
        content: url('../../layout/icons/Printer (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    .graphImage {
        content: url('../../layout/icons/graph (2).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    .resultsContainer {
        background-color: whitesmoke;
        margin: 1rem;
        margin-right: 2rem;
        border-radius: 1rem;
    }

    .submit {
        width: 220px;
        margin-bottom: 1rem;
        font-size: 18px;
        font-weight: 700;
        border-radius: 2rem;
    }

    .graphCheckCol {
        max-width: 13px;
        max-height: 13px;
        margin-top: 8px;
        margin-left: 0px;
        margin-right: 5px;
    }

    input[type=checkbox] {
        transform: scale(1.6);
        margin-left: 8px;
    }

    .results-chart-container {
        padding: 0px 40px;
    }
}
@include media-breakpoint-down(md) {
    .main-questionnaire-overview-container {
        padding-left: 30px;
        padding-right: 30px;

        .segment {
            margin-top: 2px;
            margin-left: 0px;
            margin-right: 0px;

            ul {
                padding-left: 0px;
            }
        }

        .subSegment {
            max-height: unset;

            &.graph {
                max-height: unset;
            }
        }

        .resultsContainer {
            margin: 0px;
        }

        .results-chart-container {
            padding: 0px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .main-questionnaire-overview-container {
        padding-left: 0px;
        padding-right: 0px;
    }

}