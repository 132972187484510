@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.questionnaire-users-import-main-container {
    .import-results {
        height: 200px;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
}

