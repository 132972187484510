@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.question-multiplechoice {

    .btn-response, .btn-response:focus {
        width: calc(25% - 10px);
        min-height: 100px;
        border-radius: 20px;
        background-color: $white !important;
        //width: 100%;
        margin-bottom: 15px;
        margin-left: 5px;
        margin-right: 5px;
        border: 1px solid $skillshub-blue !important;
        color: $black;
        overflow: auto;
        // font-size: 1.2rem;
        // font-weight: 500;

        &.active {
            background-color: $skillshub-blue !important;
            color: $white;
            box-shadow: none !important;
        }
    }

    @include media-breakpoint-down(md) {
        .btn-response, .btn-response:focus {
            width: calc(50% - 10px);
        }
    }

    @include media-breakpoint-down(sm) {
        .btn-response, .btn-response:focus {
            width: calc(100% - 10px);
        }
    }
}
