@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.pills-nav-bar {
    justify-content: center;

    .nav-item {
        a {
            border-style: solid;
            border-color: $primary;
            border-width: 1px;
            border-left-style: none;
            border-radius: 0px 0px;
            background-color: $white;
            cursor: pointer;

            &:hover {
                background-color: darken($primary, 10);
                border-color: darken($primary, 10);
                color: $white;
            }
        }

        &:first-child {
            a {
                border-top-left-radius: 1rem;
                border-bottom-left-radius: 1rem;
                border-left-style: solid;
            }
        }

        &:last-child {
            a {
                border-top-right-radius: 1rem;
                border-bottom-right-radius: 1rem;
            }
        }


        .active {
            opacity: unset;

            &:hover {
                background-color: darken($primary, 10);
                border-color: darken($primary, 10);
                color: $white;
            }
        }
    }
    // Handle the textColour options
    //
    &.pills-nav-bar-text-color-inherit {
        // Nothing to do here.
    }

    &.pills-nav-bar-text-color-white {
        color: $white;
    }

    &.pills-nav-bar-text-color-primary {
        color: $primary;
    }
}

// When we are under a sticky toolbar, center our items.
.sticky-toolbar-stuck {
    .pills-nav-bar {
        justify-content: center;
    }
}

@include media-breakpoint-down (sm) {
    .pills-nav-bar {
        font-size: 12px;

        .nav-link{
            padding: 0.4rem 0.5rem;;
        }
    }
}