@import "../../styles/_colors.scss";

$response-type-selector-color: $primary;

.question-main-container {
    .btn-add-type{
        font-size: 0.5rem;
        padding: 2px 5px;
        border-radius:5px;
        margin-bottom: 5px;
        margin-left: 20px;
    }
}

.response-type-selectors {
    color: $response-type-selector-color;

    .response-type-selector {
        color: $response-type-selector-color;
        border: 1px solid $response-type-selector-color;
        cursor: pointer;
        margin-right: 3px;
        height: 5rem;
        width: 100%;
        border-radius: 2px;

        &:hover {
            color: $white;
            background-color: $response-type-selector-color;
        }

        &.active {
            color: $white;
            background-color: $response-type-selector-color;
        }

        .response-type-title {
            font-size: 1rem;
            font-weight: 700;
        }
    }

    .response-type-text {
        text-align: center;
    }
}

.multiple-choice-possible-responses-container {
    input {
        display: inline-block;
        margin-left: 10px;
        width: 94%;
        border-color: $response-type-selector-color;

        &:disabled {
            background-color: $white;
            border-color: $gray-100;
        }
    }
}