@import "../../styles/_breakpoints.scss";

@include media-breakpoint-up(md) {
    body:not([data-sidebar-required="true"]) {
        .navbar-toggler {
            display: none;
        }

        .sidebar {
            display: none;
        }

        main, .main {
            margin-left: 0px !important;
        }

        .app-footer {
            margin-left: 0px !important;
        }
    }
}
