@import "../../styles/_colors.scss";

$report-splash-page-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(../shared/background/bigstock-Close-Up-Of-Laptop-With-Graphs-423740006.jpg);

$report-splash-page-logo: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(IMPACTS_LOGO_NEW2022.png);

.report-splash-container {
    max-width: 1200px;
    border-radius: 0;
    border: 1px solid lighten($skillshub-blue,20);
    box-shadow: 5px 10px 20px 5px $card-color;
    margin-bottom: 10px;

    .report-container {
        margin: 0px -15px 0px -15px;
        padding: 0px;

        .report-image-container {
            background-image: $report-splash-page-image;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            min-height: 900px;
        }

        .report-splash-title {
            text-align: center;
            font-size: 2rem;
        }

        .report-splash-user {
            padding-top: 10px;
            padding-bottom: 5px;
            text-align: center;
            font-size: 1.5rem;
        }
    }

    .splash-footer {
        margin-top: 2em;
        margin-bottom: 2em;
        border-radius: 10px;
        border: 3px solid $primary;
        padding: 20px;
        display: flex;

        .brand-logo {
            background-image: $report-splash-page-logo;
            background-position: center;
            background-size: auto 120px;
            background-repeat: no-repeat;
        }
    }
}
