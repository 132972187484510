@import "../../../styles/_colors.scss";

.search-input {
    .search-input-input {
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        padding-left: 20px;


        &.form-control:focus {
            color: #5c6873 !important;
            background-color: $white !important;
            border-color: $gray-200 !important;
            outline: 0;
            box-shadow: 0 0 0 0.01rem $white !important;
        }
    }

    .search-input-button {
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        padding-right: 15px;

        &.btn-secondary {
            background-color: $white !important;
            border-color: $white !important;
        }

        &.btn-secondary:focus, .btn-secondary.focus {
            box-shadow: 0 0 0 0.01rem $white !important;
        }

        &.btn-secondary:hover {
            background-color: $gray-500 !important;
            border-color: $white !important;
        }

        &:not(:hover) {
            background-color: $white;
            color: $gray-500;
            border-left-style: none;
            border-color: $gray-200 !important;
        }
    }
}
