@import "../../styles/_colors.scss";

.dashboard {
    margin-bottom: 30px;

    .dashboard-section-header {
        margin: 15px;
        color: $gray-base;
    }

    .col-inline-heading-button {
        padding-top: 15px;
        padding-left: 0px;
    }

    .dashboard-card-container {
        min-height: 5rem;
        border-radius: 0.5rem;
        //background-color: $white;
    }
}
