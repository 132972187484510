@import "../../styles/_breakpoints.scss";

.sort-fields-column{
    text-align: right;
}

@include media-breakpoint-down(sm) {

    .sort-fields-column {
        font-size: 12px;
        text-align: center;
    }
}
