@import "../../../styles/_breakpoints.scss";
.progress-bar-container {
    margin-top: 10px;
}

@include media-breakpoint-up(sm) {

    .progress-bar-container {
        margin-top: -20px;
    }
}