@import "../../styles/_colors.scss";

$login-screen-target-image: url('../shared/background/target.png');
$login-logo-image: url(../../components/shared/background/loginLogo.png);


.register-page {
    height: 100%;
    margin: 0;
    padding: 0 !important;

    .forgotPassword {
        color: $black;
        margin-left: auto;
        margin-right: auto;

        &:hover {
            color: $skillshub-blue;
        }
    }

    .register-page-row {
            height: 100%;

            .register-screen-target-column {
                background-color: $skillshub-blue;
                overflow: hidden;

                .login-target-image {
                    background-image: $login-screen-target-image;
                    margin-top: 10%;
                    margin-left: 27%;
                    height: 80%;
                    width: 100%;
                    background-position: right;
                    background-size: contain;
                    background-repeat: no-repeat;
                    overflow: hidden;
                    opacity: 0.5;
                }
            }


            .register-screen-input-column {
                margin-top: 4%;

                .impacts-logo-login-row {
                    height: 50px;
                    margin-bottom: 5%;
                    margin-top: 25%;

                    .impacts-logo-login-image {
                        background-image: $login-logo-image;
                        margin-left: 10px;
                        padding-left: unset;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        background-position: left;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }


                .login-impacts-text {
                    color: $skillshub-blue;
                }

                .custom-control-input:checked ~ .custom-control-label::before {
                    color: $white;
                    border-color: $skillshub-darkblue;
                    background-color: $skillshub-blue;
                }

                .register-class {
                    .register-button { 
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
