@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.add-questionnaire-main-container {
    label {
        font-weight: 600;
    }

    .nav-tiles {
        justify-content: center;

        .nav-item {

            .nav-link {
                height: 12rem;
                border-radius: 5px;
                color: $skillshub-grey;
                background-color: $white;
                border-color: $skillshub-grey;

                &:hover {
                    background-color: darken($skillshub-blue,10);
                    color: $white;
                }

                &.active {
                    background-color: $skillshub-blue;
                    border-color: $skillshub-blue;
                    color: $white;

                    &:hover {
                        background-color: darken($skillshub-blue,10);
                    }
                }

                .navitem-text {
                    height: 4rem;
                    font-size: 1.2rem;
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .nav-tiles {
            .nav-item {

                .nav-link {
                    height: 9.5rem;

                    &:hover {
                    }

                    .navitem-text {
                        font-size: 1rem;
                        height: 4.5rem;
                        line-height: 20px;
                    }
                }

                .nav-icon {
                    font-size: 55px;
                }
            }
        }
    }
}
