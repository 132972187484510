@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.nav-tiles {
    // Hide top level titles as they will usually overlap with the banner text.
    .nav-item.nav-title {
        display: none;
        // But display the child titles as they let us seperate things out.
        &.nav-title-child {
            display: block;
            width: 100%;
            text-align: left;
            font-size: 1.4em;
        }
    }
    // Show nav-item as tiles.
    .nav-item {
        display: inline-block;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        //font-size: 1.1em;
        font-weight: 500;


        .nav-link {
            width: 100%;
            height: 150px;
            border: 1px solid $primary;
            background-color: rgba($light-bg, 0.8);
            color: $primary;

            &:hover {
                background-color: rgba($light-bg, 0.9);
            }
        }

        .nav-icon {
            width: 100%;
            font-size: 80px;
            display: block;
            margin-bottom: 4px;
        }
    }
    // Size the items so they are 2 on xs, 3 on sm and md, 4 on lgd and above.
    .nav-item {
        width: 50%;
    }

    @include media-breakpoint-up(sm) {
        .nav-item {
            width: 33%;
        }
    }

    @include media-breakpoint-up(lg) {
        .nav-item {
            width: 25%;

            // Slightly increase the icon size on lg screens.
            .nav-icon {
                font-size: 90px;
            }
        }
    }
}

