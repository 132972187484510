@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";


.customiser-container {
    height: 100%;
    border: none;


    h3 {
        //color: $primary;
    }

    .customiser-includes-container {
        border: 1px solid lighten($skillshub-blue, 40);
        padding: 10px;
        border-radius: 0.2rem;
        box-shadow: 5px 10px 20px 5px $card-color;

        .custom-switch {
            padding-left: 0px;
        }

        .customiser-subsetting {
            padding-left: 30px;
        }
    }
}


@include media-breakpoint-up(sm) {
    .customiser-container {
        border-left: 1px solid $gray-200;
        padding-left: 20px;
    }
}
