@import "../../styles/_breakpoints.scss";
@import "../../styles/_colors.scss";

.sidebar {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding-top: 60px;
    background-color: $darkgoldenrod;

    .menu-nav {
        min-height: 0px !important; // Overide the min-height: 100% from coreui as we use multiple navs to manage our sidebar.
    }

    @include media-breakpoint-up(md) {
        padding-top: 40px;

        .menu-nav {
            display: none !important;
        }
    }

    .nav-link {
        &.active {
            // Rounder the border of the active item.
            border-radius: 0.5rem;
        }

        .nav-icon{
            color: $white;
        }
    }

    .sidebar-minimizer {
        background-color: transparent;
        border-bottom-right-radius: 50px;

        &:hover {
            background-color: $skillshub-purple;
        }
    }
}

.sidebar-minimized {
    .sidebar {
        .nav-link {
            &:hover {
                background-color: $skillshub-purple !important;
            }
        }
    }

    .sidebar-minimizer {
        background-color: transparent !important;
    }
}