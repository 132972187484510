.card-header-collapse {
    cursor: pointer;

    // Maintain the rounded card look while we are collapsed.
    &.card-header-collapse-closed {
        &:first-child {
            border-radius: calc(2rem - 1px) calc(2rem - 1px) calc(2rem - 1px) calc(2rem - 1px);
        }
    }
}
