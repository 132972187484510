@import "../../../styles/_breakpoints.scss";
@import "../../../styles/_colors.scss";

.btn-add-type {
    font-size: 0.5rem;
    padding: 2px 5px;
    border-radius: 5px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.analyser-segment {
    input[type=checkbox] {
        transform: scale(1.6);
        margin-left: 8px;
    }

    ul {
        padding-left: 31px;
    }
}

.results-by-label{
    width: 100px !important;
    padding-bottom: 5px;
}

.common-icon {
    width: 25px;
    height: 25px;
    display: inherit;
    margin-top: 0.3rem;
}

.analyser {

    &-graphCheckCol {
        max-width: 13px;
        max-height: 13px;
        margin-top: 8px;
    }

    &-changeIconCol {
        position: relative;
        left: 28%;
        max-width: 25px;
    }

    &-header {
        height: 60px;
        padding-top: 10px;
    }

    &-heading {
        color: $skillshub-blue;
    }

    &-segment {
        border: solid 2px;
        border-color: $gray-200;
        margin-top: 1rem;
        margin-left: 2px;
        margin-right: 2px;
        //width: 35.199vw;
    }

    &-subSegment {
        padding-top: 0.5rem;
        border-bottom: solid 2px;
        border-color: $gray-200;
        padding-left: 1rem;
        padding-right: 1rem;
        max-height: 485px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
    }

    &-submitButton {
        width: 220px;
        margin-bottom: 1rem;
        font-size: 18px;
        font-weight: 700;
        border-radius: 2rem;
    }

    &-dateInput::-webkit-calendar-picker-indicator {
        opacity: 0;
        cursor: pointer;
    }

    &-dateInput {
        background-image: url("../../layout/icons/Calendar (blue).png");
        background-size: 1rem;
        background-repeat: no-repeat;
        background-position-x: 97%;
        background-position-y: 50%;
    }

    &-imageButton {
        background-color: transparent !important;
        color: none;
        width: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        fill: transparent;
        transition: none;

        &.btn-secondary {
            background-color: transparent !important;
            border-color: transparent !important;

            &:hover {
            }
        }

        &:active, &:focus {
            box-shadow: none;
            background-color: transparent;
            border-color: transparent !important;
            border: none;
            outline: none;
            box-shadow: none;
        }

        &:hover {
            box-shadow: none;
            background-color: transparent;
        }

        &-closeResults {
            color: $skillshub-blue;
            background-color: transparent !important;
            border: none;
            outline: none;
            padding-left: 0;

            &:hover {
                color: $skillshub-blue;
            }

            &.btn-secondary {
                background-color: transparent !important;
                border-color: transparent !important;

                &:hover {
                }
            }
        }
    }



    &-pdfImage {
        content: url('../../layout/icons/PDF (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    &-printImage {
        content: url('../../layout/icons/Printer (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    &-graphImage {
        content: url('../../layout/icons/graph (2).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
    }

    &-arrowImage {
        content: url('../../layout/icons/Arrow (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: pointer;
        position: relative;
        bottom: 0.3rem;
    }

    &-hamburger {
        content: url('../../layout/icons/Move (blue).png');
        width: 25px;
        height: 25px;
        display: inherit;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &-upArrow {
        content: url('../../layout/icons/Up Arrow (blue).png');
        width: 25px;
        height: 25px;
        display: inline;
        margin-top: 0.3rem;
    }

    &-downArrow {
        content: url('../../layout/icons/Down Arrow (blue).png');
        width: 25px;
        height: 25px;
        display: inline;
        margin-top: 0.3rem;
    }

    &-tick {
        content: url('../../layout/icons/Tick (blue).png');
        width: 13px;
        height: 13px;
        margin-bottom: 7px;
        border-style: solid;
        border-width: 0.5px;
        border-color: gray;
        border-radius: 20%;
        background-color: white;
    }

    &-tickShowBy {
        content: url('../../layout/icons/Tick (blue).png');
        width: 11px;
        height: 11px;
        z-index: 100;
        position: relative;
        right: 0.75rem;
        bottom: 0.17rem;
        pointer-events: none;
        background-color: white;

        &-section {
            content: url('../../layout/icons/Tick (blue).png');
            width: 11px;
            height: 11px;
            z-index: 100;
            position: relative;
            right: 0.75rem;
            bottom: 0.19rem;
            pointer-events: none;
            background-color: white;
        }

        &-question {
            content: url('../../layout/icons/Tick (blue).png');
            width: 11px;
            height: 11px;
            z-index: 100;
            position: relative;
            right: 0.75rem;
            bottom: 0.19rem;
            pointer-events: none;
            background-color: white;
        }
    }

    &-tickTime {
        content: url('../../layout/icons/Tick (blue).png');
        width: 11px;
        height: 11px;
        z-index: 100;
        position: relative;
        top: 50%;
        right: 5%;
        pointer-events: none;
        background-color: white;
    }

    &-campaignIcon {

        &-AccidentsAtWork {
            content: url('../../layout/icons/Campaign Icons/Accidents at work (blue).png');
        }

        &-Accountability {
            content: url('../../layout/icons/Campaign Icons/Accountability (blue).png');
        }

        &-Actions {
            content: url('../../layout/icons/Campaign Icons/Actions (blue).png');
        }

        &-CoachingAndFeedback {
            content: url('../../layout/icons/Campaign Icons/Coaching & Feedback (blue).png');
        }

        &-Communication {
            content: url('../../layout/icons/Campaign Icons/Communication (blue).png');
        }

        &-ConfidenceLevels {
            content: url('../../layout/icons/Campaign Icons/Confidence Levels (blue).png');
        }

        &-ContinuousLearning {
            content: url('../../layout/icons/Campaign Icons/Continuous Learning (blue).png');
        }

        &-CostSaving {
            content: url('../../layout/icons/Campaign Icons/Cost Saving (blue).png');
        }

        &-CustomerExperience {
            content: url('../../layout/icons/Campaign Icons/Customer Experience (blue).png');
        }

        &-DifficultHonestConversations {
            content: url('../../layout/icons/Campaign Icons/Difficult_Honest Conversations (blue).png');
        }

        &-EffectiveCommunication {
            content: url('../../layout/icons/Campaign Icons/Effective Communication (blue).png');
        }

        &-Efficiency {
            content: url('../../layout/icons/Campaign Icons/Efficiency (blue).png');
        }

        &-Engagement {
            content: url('../../layout/icons/Campaign Icons/Engagement (blue).png');
        }

        &-HealthAndSafety {
            content: url('../../layout/icons/Campaign Icons/Health & Safety (blue).png');
        }

        &-ImprovedRebateManagement {
            content: url('../../layout/icons/Campaign Icons/Improved Rebate Management (blue).png');
        }

        &-IncreaseInPerformance {
            content: url('../../layout/icons/Campaign Icons/Increase in Performance (blue).png');
        }

        &-Intentions {
            content: url('../../layout/icons/Campaign Icons/Intentions (blue).png');
        }

        &-LearningPoints {
            content: url('../../layout/icons/Campaign Icons/Learning Points (blue).png');
        }

        &-ManagingChange {
            content: url('../../layout/icons/Campaign Icons/Managing Change (blue).png');
        }

        &-MeetingTargets {
            content: url('../../layout/icons/Campaign Icons/Meeting Targets (blue).png');
        }

        &-MentalHealth {
            content: url('../../layout/icons/Campaign Icons/Mental Health (blue).png');
        }

        &-PersonalActivity {
            content: url('../../layout/icons/Campaign Icons/Personal Activity (blue).png');
        }

        &-PostProgrammeActions {
            content: url('../../layout/icons/Campaign Icons/Post-Programme Actions (blue).png');
        }

        &-PreActivityGoals {
            content: url('../../layout/icons/Campaign Icons/Pre-activity Goals (blue).png');
        }

        &-ProblemSolvingAndDecisionMaking {
            content: url('../../layout/icons/Campaign Icons/Problem Solving & Decision Making (blue).png');
        }

        &-ProcessImprovement {
            content: url('../../layout/icons/Campaign Icons/Process Improvement  (blue).png');
        }

        &-Profit {
            content: url('../../layout/icons/Campaign Icons/Profit (blue).png');
        }

        &-ResilienceAndStress {
            content: url('../../layout/icons/Campaign Icons/Resilience & Stress (blue).png');
        }

        &-Results {
            content: url('../../layout/icons/Campaign Icons/Results (blue).png');
        }

        &-Revenue {
            content: url('../../layout/icons/Campaign Icons/Revenue (blue).png');
        }

        &-StaffRetention {
            content: url('../../layout/icons/Campaign Icons/Staff Retention (blue).png');
        }

        &-TimeSaver {
            content: url('../../layout/icons/Campaign Icons/Time Saver (blue).png');
        }

        &-Wellbeing {
            content: url('../../layout/icons/Campaign Icons/Wellbeing (blue).png');
        }

        &-default {
            content: url('../../layout/icons/Campaign Icons/General Blue.png');
        }
    }

    &-resultsContainer {
        background-color: $gray-100;
        border-radius: 1rem;
        padding-bottom: 1rem;
        margin-bottom: 1.5rem;
    }

    &-dragComponent {
        background-color: $gray-100;
        margin: 1rem;
        margin-left: -1rem;
        border-radius: 25px;
        max-width: 98%;
        padding-top: 7px;
        padding-bottom: 7px;

        .fa-minus {
            color: $skillshub-blue;
            width: 17px !important;
            margin-left: 5px;
            margin-right: 5px;
        }

        .dragable-name-container {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        h5, h6 {
            color: $skillshub-blue;
            display: inline;
        }
    }

    &-tagContainer {
        overflow: scroll;
        max-height: 10vw;
        scrollbar-width: none;
    }
}

.analyser-imageButton-closeResults, .analyser-imageButton {
    &:focus, &:active {
        box-shadow:none !important;
        color: $skillshub-blue !important;
    }
}

.question-area {
    border-color: $black;
    border-width: 1px;
    border-style: solid;
    background-color: $white;
}


@include media-breakpoint-down(lg) {
    .analyser-segment {
        margin-left: 0px;
        margin-right: 0px;
        padding-right: 0px;

        &.analyser-segment-right{
            padding-right: 10px;
            padding-left: 0px;
        }
    }
}

@include media-breakpoint-down(md) {
    .main-analyser-container{
        padding-left: 30px;
        padding-right: 30px;
    }

    .analyser-resultsContainer {
        margin: 5px 0px;
    }

    .analyser-segment {
        margin-top: 2px;
        margin-left: 0px;
        margin-right: 0px;
        padding-right: 10px;
        &.analyser-segment-right{
            padding-left: 10px;
        }

        ul {
            padding-left: 0px;
        }
    }

    .analyser-subSegment {
        max-height: unset;
    }

    .analyser-dragComponent {
        min-height: 37px;
        margin-left: 0px;
        margin-right: 0px;
        max-width: unset;

        .analyser-hamburger {
            height: 18px;
            width: 18px;
        }

        .common-icon {
            height: 18px;
            width: 18px;
            margin-top: 2px;
        }

        h5 {
            font-size: 14px;
        }

        .analyser-upArrow, .analyser-downArrow {
            width: 20px;
            height: 20px;
            margin-top: 0px;
        }

        h6 {
            font-size: 12px;
        }

        .analyser-imageButton {
            width: 18px;
            height: 18px;
            margin-top: 2px;

            .analyser-arrowImage {
                margin-top: 10px;
            }
        }
    }
}
@include media-breakpoint-down(sm) {
    .main-analyser-container {
        padding-left: 0px;
        padding-right: 0px;
    }

    .analyser-header {
        height: 38px;
    }
}