@import "../../styles/_colors.scss";
@import "../../styles/_breakpoints.scss";

.campaign-category-header {
    margin: 15px;
    color: $gray-base;
}

.see-impacts {
    float: right;
    margin-top: 5px;
}


@include media-breakpoint-down(sm) {
    .campaign-category-header {
        margin: 5px 10px 5px 10px;
    }
}