@import "../../styles/_colors.scss";

.individual-report-container {
    max-width: 1200px;
    border-radius: 0;
    border: 1px solid lighten($skillshub-blue,20);
    box-shadow: 5px 10px 20px 5px $card-color;

    .report-page-header {

        img {
            margin-top: 2em;
            max-height: 60px;
        }
    }

    .report-title {
        padding-top: 10px;
        padding-bottom: 30px;
        border-bottom: 5px solid;
        text-align: center;
        font-size: 2rem;

        .report-sub-title {
            border: none;
            font-size: 1.5rem;
        }
    }

    .report-questionnaire-description {
        padding: 10px;
        color: $gray-500;
    }

    .report-session-details {
        padding: 20px;
        border: 1px solid $gray-300;
        margin-bottom: 10px;

        .report-session-details-row {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        label {
            font-weight: 600;
            display: block;
        }
    }

    .section-container {
        border: 1px solid lighten($skillshub-blue, 40);
        margin-bottom: 10px;
        padding: 10px;

        .section-header {
            color: $skillshub-blue;
            font-size: 2rem;
            font-weight: 600;

            .section-initial {
                font-size: 2.5rem;
            }
        }

        .section-description {
            font-size: 1.4rem;
            font-weight: 500;
            color: $gray-500;
        }

        .question-container {
            .question-text {
                margin-top: 20px;
                background-color: $gray-100;
                width: 100%;
                padding: 10px;
                font-size: 1.4rem;
                font-weight: 500;
            }

            .user-container {
                background-color: $white;
                padding-top: 3px 3px 15px 15px;
                font-weight: 500;

                .user-name {
                    padding: 10px 25px 5px 25px;
                    font-size: 1.1rem;
                }

                .response-container {
                    border: 1px solid $gray-200;
                    padding: 10px;
                    min-height: 42px;
                    margin-bottom: 3px;

                    .response-pre-text {
                        color: $white;
                        font-weight: 600;
                        background-color: $gray-400;
                        padding: 4px 10px 4px 10px;
                        border-radius: 15px;
                        margin-right: 10px;

                        &.fixed-width-20 {
                            width: 80px;
                            text-align: center;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
}