@import "../../../styles/_colors.scss";

.generated-index {
    .generated-index-header {
        font-size: 1.2rem;
        font-weight: 600;
    }

    ul {
        list-style-type: none;
        padding-left: 0px;

        .generated-index-link {
            color: $primary;
            font-weight: 500;
            font-size: 1rem;
            cursor: pointer;

            .generated-index-link-prefix {
                color: $skillshub-blue;
                font-size: 1.2rem;
                display: inline-block;
                min-width: 3rem;
                margin-right: 1rem;
            }
        }
    }
}
