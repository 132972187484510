@import "../../../styles/_colors.scss";

.question-pickone {

    .btn-response {
        height: 100px;
        border-radius: 20px;
        background-color: $white !important;
        width: 100%;
        margin-bottom: 15px;
        border: 1px solid $skillshub-blue !important;
        color: $gray-600;
        overflow: auto;
        font-size: 1.2rem;
        font-weight: 500;

        &:focus, &.active {
            background-color: $skillshub-blue !important;
            color: $white;
            box-shadow: none !important;
        }
    }
}
