@import "../../../styles/_colors.scss";

.report-summary-container {
    padding: 20px;
    border: 1px solid lighten($primary, 45);
    margin-bottom: 10px;

    label {

        &.label-header {
            font-size: 1.2rem;
        }

        font-weight: 600;
        display: block;
    }

    .report-summary-description, .report-summary-learningUnit {
        margin-top: 10px;
        border-top: 1px solid lighten($primary, 45);
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .report-summary-header {
        color: $primary;
        font-size: 2rem;
        font-weight: 600;
    }

    .report-dashboard-header {
        color: $primary;
        font-size: 2rem;
        font-weight: 600;
    }

    .report-summary-learningUnit {
        border-bottom: 1px solid lighten($primary, 45);
        margin-bottom: 10px;

        .report-summary-learningUnit-row {
            padding-bottom: 10px;
        }
    }
}
