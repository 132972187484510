@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.select-question-modal {
    .modal-header {
        background-color: $dark-bg;
        color: $dark-color;
    }

    .introduction {
        font-size: 1rem;
        color: $primary;
        background-color: lighten($primary,50%);
        padding: 20px;
    }

    .section-header {
        font-size: 1.2rem;
        color: $primary;
    }

    .nav-tiles {
        .nav-item {
            .nav-icon {
                font-size: 50px;
            }

            .nav-link {
                h2 {
                    color: $primary;
                }

                &:hover {
                    color: darken($primary,10%);

                    h2 {
                        color: darken($primary,10%);
                    }
                }

                &.active {
                    background-color: $primary;
                    color: $white;

                    h2 {
                        color: $white;
                    }
                }
            }
        }
    }

    .preview-container {
        border-top: 1px solid $primary;
        border-bottom: 1px solid $primary;
        padding: 10px 20px 20px 20px;
        margin-bottom: 10px;
        margin-top: 10px;
    }

    .question-main-container {
        label {
            color: $gray-800;
            font-weight: 600;
        }
    }

    @include media-breakpoint-down(md) {
        .question-main-container {
            padding: 5px;
        }

        [data-toggle="tooltip"] {
            display: none;
        }

        .card-body {
            padding: 5px;

            .nav-tiles {

                .nav-item {
                    width: 50%;
                    font-size: 10px;

                    h2 {
                        font-size: 1rem;
                    }

                    .nav-icon {
                        font-size: 30px;
                    }

                    .nav-link {
                        height: 7rem;


                        .navitem-text {
                            height: 3rem;
                            font-size: 1rem;
                            line-height: 1.1;
                        }
                    }
                }
            }
        }

        .preview-container {
            padding: 10px 0px 20px 0px;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }
}
