@import "../../../../node_modules/react-circular-progressbar/dist/styles.css";
@import "../../../styles/_colors.scss";

.completion-progress-bar {
    max-height: 80px;
    max-width: 80px;

    .completion-progress-bar-heading {
        font-size: 0.7rem;
        line-height: 1;
    }

    .completion-progress-bar-value {
        font-size: 1.3rem;
    }

    &.completion-progress-bar-campaign {
        .CircularProgressbar-path {
            stroke: $campaign-color;
        }
    }

    &.completion-progress-bar-selfservice {
        .CircularProgressbar-path {
            stroke: $selfservice-color;
        }
    }
}
