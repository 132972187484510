@import "../../../../styles/_colors.scss";
@import "../../../../styles/_breakpoints.scss";

.questionnaire-section {
    border: 1px solid lighten($skillshub-grey, 40);

    .card-header {
        border: 1px solid lighten($skillshub-grey, 40) !important;
        background-color: $white !important;
        border-color: $skillshub-grey !important;
        color: $skillshub-grey !important;
        font-weight: 600;
        font-size: 1.1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &.section-odd {
        .card-header {
            background-color: lighten($skillshub-grey, 15);
        }
    }

    &.section-even {
        .card-header {
            background-color: $skillshub-grey;
            .section-title{
                padding-top: 4px;
            }
        }
    }

    .list-group-item {
        cursor: pointer;

        &.question-list-group-item-odd {
            background-color: $gray-100;
        }
    }


    .question-text {
        padding-top: 8px;

        &.question-text-bold {
            color: $gray-900;
            font-weight: 600;
        }

        .html-display{
            display: inline-block;
        }
    }


    .card-body {
        color: $gray-800;
    }

    .no-results {
        font-weight: 600;
        text-align: center;
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;

        .card-header {
            padding: .5rem 1rem;
        }

        .card-body {
            padding: 10px;

            .form-buttons {
                text-align: center;

                .section-button-group, .question-button-group {
                    margin-top: 3px;

                    .btn {
                        padding: 6px 4px;
                    }
                }
            }

            .list-group-item {
                padding: 12px;
            }
        }
    }

    .list-group-item:first-child {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .list-group-item:last-child {
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
}


