@import "../../../styles/_colors.scss";

.select-questionnaire-modal {
    .modal-header {
        background-color: $dark-bg;
        color: $dark-color;
    }

    .table-responsive {
        margin-top: 0.5rem;
        max-height: 70vh;
        overflow-y: auto;
    }
}

h2 {
    color: black;
}
