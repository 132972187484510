@import "../../../styles/_colors.scss";

.analyser-checklist-modal {

    .analyserchecklist-container {
        input[type=checkbox] {
            transform: scale(1.6);
            margin-left: 8px;
        }
    }

    .arrow-image {
        content: url('../../layout/icons/Arrow (blue).png');
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-right: 5px;
    }

    .selections-label {
        color: $skillshub-blue;
        display: inline-block;
        font-size: 13px;
    }

    h6 {
        margin-bottom: 0;
    }

}

.analyserchecklist-container {
    .group-table-row {
        color: $white;
        position: relative;
        background-color: $skillshub-blue !important;

        svg {
            font-size: 35px;
            cursor: pointer;
            margin-top: -6px;
        }

        .show{
            display: inline-block;
        }

        .hide{
            display: none;
        }
    }
}
