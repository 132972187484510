﻿// Colors
//
// These colours can be included by any scss files for components if required.
// They also get included in in the main imports for bootstrap and coreui by the layout to override their colours.

// Brand colour pallete.

// these are the original colors
//$esitu-pink: #ec0b62;
//$esitu-darkblue: #2f5597;
//$esitu-lightblue: #00c4de;

// these are unique to skillshub impacts
$skillshub-blue: #1188FF; // primary color
$skillshub-purple: #b7297d;
$skillshub-orange: #ff9700;
$skillshub-green: #0f8b95;
$skillshub-brown: #a67c52;
$skillshub-yellow: #e0d600;
$skillshub-darkblue: #0055aa; // skillshub blue darkened by 20%
$skillshub-lightblue: #43A0FC; // skillshub blue lightened by 10%
$darkgoldenrod: #b8860b;
$skillshub-grey: #555555; // primary color

// Main colours for the app's theme.
$primary: $skillshub-purple;
$secondary: $skillshub-lightblue;
$success: #4dbd74;
$info: #63c2de;
$warning: #ffc107;
$danger: #f86c6b;
$light: $skillshub-lightblue;
$dark: $skillshub-darkblue;

// Useful monochrome colours.
$white: #fff;
$black: #000;
$gray-base: #181b1e;
$gray-100: lighten($gray-base, 85%);
$gray-200: lighten($gray-base, 80%);
$gray-300: lighten($gray-base, 70%);
$gray-400: lighten($gray-base, 60%);
$gray-500: lighten($gray-base, 50%);
$gray-600: lighten($gray-base, 40%);
$gray-700: lighten($gray-base, 30%);
$gray-800: lighten($gray-base, 10%);
$gray-900: lighten($gray-base, 5%);


// Explict light and dark pairs.
$dark-bg: $skillshub-darkblue;
$dark-color: $white;
$light-bg: $white;
$light-color: $gray-800;

// Colours pairs for main areas.
$body-bg: $white;
$body-color: $gray-900;
$main-bg: $white;
$main-color: $gray-900;

// Colours for types of questionnaire
$template-color: $skillshub-brown;
$campaign-color: $skillshub-blue;
$selfservice-color: $skillshub-green;

$card-color: #e6e1e1;

// Colours for /me cards.
$red: #ff0303;
$green: #07cf00;