@import "../../styles/_colors.scss";

.questionnaire-summary-main-container {
    .summary-details {
        .summary-group {
            margin-bottom: 1rem;

            label {
                font-weight: 500;
                margin-bottom: 0;
            }

            .summary-data {
                display: block;
                font-weight: 400;
                padding-left: 1rem;
            }

            .summary-data-subtext {
                font-size: 0.8rem;
                display: block;
                font-weight: 400;
                padding-left: 1rem;
            }
        }
    }

    .nav-tiles {

        .nav-item {
            width: 12rem;
            padding: 0px;
            margin-right: 3px;
            margin-left: 3px;

            .nav-link {
                height: 12rem;
                border-radius: 5px;
                color: $white;
                background-color: $skillshub-blue;
                border-color: $skillshub-blue;

                &:hover {
                    background-color: darken($skillshub-blue,10);
                }

                &.active {
                    background-color: $primary;
                    border-color: $primary;

                    &:hover {
                        background-color: darken($primary,10);
                    }
                }

                .navitem-text {
                    height: 4rem;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .summary-preview {
        margin-top: 4rem;
    }
}