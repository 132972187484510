@import "../../styles/_colors.scss";

.html-display {
    // Format tables to they appear the same between HtmlDisplay and HtmlEditor.
    table {
        width: 100%;

        td {
            border: 1px solid $gray-300;
        }
    }
    // Embedded youtube videos should be full width and 16x9 sized.
    oembed {
        display: block;
        overflow: hidden;
        /* 16:9 aspect ratio */
        width: 100%;
        padding-top: 56.25%;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

// Special handling to not take the table-striped formatting when we are previewing html within a list.
table.table-striped {
    .html-display {
        table:not(.table-striped) {
            tbody {
                tr:nth-of-type(odd) {
                    background-color: transparent;
                }
            }
        }
    }
}
