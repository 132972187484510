@import "../../../styles/_colors.scss";
@import "../../../styles/_breakpoints.scss";

.question-measure {
    .radio {
        display: inline-block;

        label {
            width: 35px;
            font-size: 1.2rem;
            font-weight: 500;
        }

        input {
            margin-right: 0px;
            transform: scale(3);

            &:disabled:checked {
                &:after {
                    position: absolute;
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: $gray-500;
                }
            }
        }
    }

    .yes-no-input {
        .btn-response {
            height: 60px;
            border-radius: 20px;
            background-color: $white !important;
            width: 35%;
            margin-bottom: 15px;
            margin-right: 15px;
            border: 2px solid $skillshub-blue !important;
            color: $gray-600;
            overflow: auto;
            font-size: 1.2rem;
            font-weight: 500;

            &:hover, &:focus, &.active {
                background-color: $skillshub-blue !important;
                color: $white;
                border: 1px solid $gray-200 !important;
                box-shadow: none !important;
            }
        }
    }

    .scale-measure {
        .before-after-label {
            padding-left: 10px;
            width: 110px;
            font-size: 1.2rem;
            font-weight: 500;
        }

        &.single-scale{
            margin-left: 25px;
        }
    }

    @include media-breakpoint-down(sm) {
        .scale-measure {
            padding-left: 20px;
            padding-right: 20px;

            .before-after-label {
                padding-left: 0px;
            }
        }

        .radio {
            font-size: 0.8rem;

            label, .label {
                margin-right: 1.2rem;
            }
        }
    }
}
